import {
	BLOCK_SLOT_FOOTER,
	BLOCK_TYPE_NAVIGATION,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
} from '@zyro-inc/site-modules/constants';
import { getLanguageSwitcherLanguages } from '@zyro-inc/site-modules/utils/getLanguageSwitcherLanguages';
import { getEcwidPages } from '@zyro-inc/site-modules/utils/getters/getEcwidPages';
import { filterLanguageHiddenPages } from '@zyro-inc/site-modules/utils/filterHiddenPages';

const GLOBAL_BLOCK_TYPES = [
	BLOCK_TYPE_NAVIGATION,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
];

export const getPageBlocks = ({
	siteBlocks,
	pageBlocksIds,
}) => Object.fromEntries(Object.entries(siteBlocks).filter(([
	blockId,
	{
		type,
		slot,
	},
]) => {
	const isPageBlock = pageBlocksIds.includes(blockId);
	const isGlobalBlock = GLOBAL_BLOCK_TYPES.includes(type);
	const isFooterBlock = slot === BLOCK_SLOT_FOOTER;

	return isPageBlock || isGlobalBlock || isFooterBlock;
}));

export const getPageElementsFromPageBlocks = ({
	siteElements,
	blocks,
}) => {
	const pageBlocksElementIds = Object.values(blocks).map(({ components }) => components).flatMap((components) => components);

	return Object.fromEntries(Object.entries(siteElements).filter(([elementId]) => pageBlocksElementIds.includes(elementId)));
};

export const getPageData = ({
	siteData,
	locale,
	pageId,
}) => {
	const { defaultLocale = 'system' } = siteData.meta;

	const productPages = Object.fromEntries(Object.entries(siteData.languages[defaultLocale].pages)
		.filter(([, { type }]) => type === PAGE_TYPE_ECOMMERCE_PRODUCT));

	const productPageIds = Object.entries(productPages)
		.map(([id]) => id);

	const languageData = siteData.languages[locale];

	const {
		blocks: defaultLocaleBlocks,
		elements: defaultLocaleElements,
	} = siteData.languages[defaultLocale];

	const pageData = languageData.pages[pageId];

	if (!pageData) {
		return null;
	}

	const pageBlocks = getPageBlocks({
		// destructure order is important because page blocks share same blockIds
		// thus defaultLocaleBlocks need to be overriden to retain current page block data
		siteBlocks: {
			...defaultLocaleBlocks,
			...languageData.blocks,
		},
		pageBlocksIds: [
			...pageData.blocks,
			...productPageIds,
		],
	});

	const pageElements = getPageElementsFromPageBlocks({
		siteElements: {
			...defaultLocaleElements,
			...languageData.elements,
		},
		blocks: pageBlocks,
	});

	// Filter not published pages
	const filteredPages = filterLanguageHiddenPages(languageData.pages, new Date().setHours(0, 0, 0, 0));

	return {
		pages: {
			...filteredPages,
			// productPages are required for BlockEcommerceProductList
			...productPages,
		},
		blocks: pageBlocks,
		elements: pageElements,
		nav: languageData.nav,
		currentLocale: locale,
		homePageId: languageData.homePageId,
		isNavHidden: languageData.isNavHidden,
		cookieBannerAcceptText: languageData.cookieBannerAcceptText,
		cookieBannerDisclaimer: languageData.cookieBannerDisclaimer,
		cookieBannerDeclineText: languageData.cookieBannerDeclineText,
		blogReadingTimeText: languageData.blogReadingTimeText,
		metaTitle: languageData.metaTitle,
		meta: siteData.meta,
		forms: siteData.forms,
		styles: siteData.styles,
		domain: siteData.domain ?? siteData.freeDomain,
		siteId: siteData.siteId,
		ecommerceShoppingCart: siteData.ecommerceShoppingCart,
		blogCategories: siteData.blogCategories,
		languageSwitcherLanguages: getLanguageSwitcherLanguages({
			languages: siteData.languages,
			defaultLocale: siteData.meta.defaultLocale,
		}),
		currentPageId: pageId,
		languageKeys: Object.keys(siteData.languages),
		ecwidPages: getEcwidPages({
			pages: languageData.pages,
			blocks: languageData.blocks,
		}) ?? {},
	};
};
