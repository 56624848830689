<template>
	<div
		ref="searchBarRef"
		class="site-element-search-bar"
		aria-owns="search-results"
		tabindex="0"
		aria-haspopup="listbox"
	>
		<div class="site-element-search-bar__input-wrapper">
			<input
				class="site-element-search-bar__input"
				:placeholder="props.placeholderText"
				:value="props.searchTerm"
				@input="emit('update:search-term', ($event.target as HTMLInputElement).value)"
				@keydown.down.prevent="highlightNextItem"
				@keydown.up.prevent="highlightPreviousItem"
				@keydown.enter="redirectToHighlightedItem"
			>
			<div
				v-if="isSearchResultDropdownVisible"
				class="site-element-search-bar__dropdown-close"
				@click="dropdownCloseHandler"
			/>
		</div>

		<ul
			v-if="isSearchResultDropdownVisible"
			id="search-results"
			role="listbox"
			class="site-element-search-bar__dropdown"
		>
			<ZyroLoader
				v-if="props.isLoading"
				size="30px"
				:color="props.textAndIconColorHover"
				class="site-element-search-bar__loader"
			/>
			<p
				v-else-if="!props.results || props.results.length === 0"
				class="site-element-search-bar__dropdown-no-results"
			>
				{{ noResultsText }}
			</p>
			<li
				v-for="(result, index) in props.results"
				v-else
				:key="result.id"
				role="option"
				:area-selected="index === highlightedItemIndex"
			>
				<a
					ref="itemLinksRefs"
					:href="result.href"
					class="search-result-item"
					:class="{ 'search-result-item--highlighted': index === highlightedItemIndex }"
					@mouseover="highlightedItemIndex = index"
					@focus="highlightedItemIndex = index"
					@keydown.enter.prevent="redirectToHighlightedItem"
				>
					<ProductImage
						v-if="result.thumbnail"
						:src="result.thumbnail"
						:alt="result.title"
						class="search-result-item__image"
						:width="IMAGE_DIMENSIONS_PX"
						:height="IMAGE_DIMENSIONS_PX"
						:site-id="props.siteId"
						enable-srcset
					/>

					<div class="search-result-item__content">
						<h3 class="search-result-item__title">
							{{ result.title }}
						</h3>
						<p class="search-result-item__price">
							<span v-if="result.price?.length && result.isInStock">
								<span
									v-if="result.oldPrice"
									class="search-result-item__sale-price"
								>
									{{ result.oldPrice }}
								</span>
								{{ result.price }}
							</span>
							<span v-else>
								{{ translations.soldOut }}
							</span>
						</p>
					</div>
				</a>
			</li>
		</ul>
	</div>
</template>

<script setup lang="ts">
import {
	computed,
	ref,
	onMounted,
	watch,
} from 'vue';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import ProductImage from '@zyro-inc/site-modules/components/ecommerce/ProductImage.vue';
import { SiteElementSearchItem } from '@zyro-inc/site-modules/types';
import { onClickOutside } from '@vueuse/core';

const IMAGE_DIMENSIONS_PX = 45;
const IMAGE_DIMENSIONS_PX_STRING = `${IMAGE_DIMENSIONS_PX}px`;

const emit = defineEmits([
	'update:search-term',
	'is-dropdown-open',
]);

interface Props {
	placeholderText: string
	noResultsText: string
	fillColor:string
	fillColorHover: string
	textAndIconColor: string
	textAndIconColorHover: string
	resultItemHoverColor: string
	fontFamily: string
	borderColor: string
	borderColorHover: string
	borderRadius: number
	translations: Record<string, string>,
	siteId: string,
	searchTerm?: string
	results?: Array<SiteElementSearchItem> | null
	isLoading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	searchTerm: '',
	results: null,
	isLoading: false,
});

const searchBarRef = ref(null);
const isSearchResultDropdownVisible = ref<boolean>(false);

const itemLinksRefs = ref<Array<HTMLAnchorElement>>([]);
const highlightedItemIndex = ref(-1);

const isLoading = computed(() => props.isLoading);
const results = computed(() => props.results);

const borderRadiusInPx = computed(() => `${props.borderRadius}px`);

const highlightNextItem = () => {
	if (!props.results) {
		return;
	}

	if (highlightedItemIndex.value < props.results.length - 1) {
		highlightedItemIndex.value += 1;
	}
};

const highlightPreviousItem = () => {
	if (highlightedItemIndex.value > 0) {
		highlightedItemIndex.value -= 1;
	}
};

const redirectToHighlightedItem = () => {
	const highlightedItemRef = itemLinksRefs.value[highlightedItemIndex.value];

	if (!highlightedItemRef) {
		return;
	}

	const clickEvent = new MouseEvent('click', {
		bubbles: true,
		cancelable: true,
		view: window,
	});

	highlightedItemRef.dispatchEvent(clickEvent);
};

const dropdownCloseHandler = () => {
	isSearchResultDropdownVisible.value = false;
	emit('update:search-term', '');
	highlightedItemIndex.value = -1;
};

watch([
	results,
	isLoading,
], () => {
	isSearchResultDropdownVisible.value = !!results.value || isLoading.value;

	emit('is-dropdown-open', isSearchResultDropdownVisible.value);
});

onMounted(() => {
	onClickOutside(searchBarRef, () => {
		dropdownCloseHandler();
	});
});
</script>

<style lang="scss">
$icon-mask-search: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0_2145_9545' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='currentColor'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_2145_9545)'%3E%3Cpath d='M19.5422 20.5769L13.2615 14.2961C12.7615 14.7089 12.1865 15.032 11.5365 15.2653C10.8865 15.4987 10.214 15.6153 9.51916 15.6153C7.80999 15.6153 6.36348 15.0236 5.17961 13.84C3.99574 12.6564 3.40381 11.2103 3.40381 9.50157C3.40381 7.79284 3.99559 6.34616 5.17916 5.16154C6.36273 3.97694 7.80888 3.38464 9.51761 3.38464C11.2263 3.38464 12.673 3.97658 13.8576 5.16044C15.0422 6.34431 15.6345 7.79083 15.6345 9.49999C15.6345 10.2141 15.5147 10.8961 15.2749 11.5461C15.0352 12.1961 14.7153 12.7615 14.3153 13.2423L20.5961 19.5231L19.5422 20.5769ZM9.51916 14.1154C10.8076 14.1154 11.899 13.6683 12.7932 12.774C13.6874 11.8798 14.1346 10.7885 14.1346 9.49999C14.1346 8.21153 13.6874 7.12018 12.7932 6.22594C11.899 5.33171 10.8076 4.88459 9.51916 4.88459C8.23069 4.88459 7.13934 5.33171 6.24511 6.22594C5.35089 7.12018 4.90378 8.21153 4.90378 9.49999C4.90378 10.7885 5.35089 11.8798 6.24511 12.774C7.13934 13.6683 8.23069 14.1154 9.51916 14.1154Z' fill='currentColor'/%3E%3C/g%3E%3C/svg%3E";
$icon-mask-close: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cmask id='mask0_2170_12150' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'%3E%3Crect width='20' height='20' fill='black'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_2170_12150)'%3E%3Cpath d='M6.0625 14.7115L5.28848 13.9375L9.22598 9.99997L5.28848 6.06247L6.0625 5.28845L10 9.22595L13.9375 5.28845L14.7115 6.06247L10.774 9.99997L14.7115 13.9375L13.9375 14.7115L10 10.774L6.0625 14.7115Z' fill='currentColor'/%3E%3C/g%3E%3C/svg%3E";
$icon-width-search: 24px;
$icon-width-close: 20px;
$input-transition-timing-function: "ease";
$input-transition-duration: "0.2s";
$image-width: 48px;
$gap-between-image-and-content: 14px;

.site-element-search-bar {
	$this: &;

	height: 48px;
	position: relative;
	font-family: v-bind(fontFamily);

	&__input-wrapper {
		position: relative;
		height: 100%;

		&::before {
			content: "";
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			bottom: 0;
			width: $icon-width-search;
			height: $icon-width-search;
			background-color: v-bind(textAndIconColor); /* Set the desired color value */
			mask-image: url($icon-mask-search);
			/* stylelint-disable-next-line property-no-vendor-prefix */
			-webkit-mask-image: url($icon-mask-search);
		}

		&:hover,
		&:focus-within {
			&::before {
				background-color: v-bind(textAndIconColorHover);
			}
		}
	}

	&__input {
		padding: 12px 12px 12px #{12px + $icon-width-search + 8px} ;
		border-radius: v-bind(borderRadiusInPx);
		border: 1px solid v-bind(borderColor);
		font-size: 16px;
		font-weight: 400;
		line-height: 1.25;
		outline: none;
		color: v-bind(textAndIconColor);
		font-family: v-bind(fontFamily);
		background-color: v-bind(fillColor);
		width: 100%;
		height: 100%;
		transition:
			color #{$input-transition-timing-function} #{$input-transition-duration},
			border-color #{$input-transition-timing-function} #{$input-transition-duration},
			background-color #{$input-transition-timing-function} #{$input-transition-duration};

		&::placeholder {
			color: v-bind(textAndIconColor);
			transition: color #{$input-transition-timing-function} #{$input-transition-duration};
		}

		&:hover,
		&:focus {
			border-color: v-bind(borderColorHover);
			color: v-bind(textAndIconColorHover);
			background-color: v-bind(fillColorHover);

			&::placeholder {
				color: v-bind(textAndIconColorHover);
			}
		}
	}

	&__dropdown-close {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		bottom: 0;
		width: $icon-width-close;
		height: $icon-width-close;
		background-color: v-bind(textAndIconColor); /* Set the desired color value */
		mask-image: url($icon-mask-close);
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-webkit-mask-image: url($icon-mask-close);

		&:hover {
			cursor: pointer;
			background-color: v-bind(textAndIconColorHover);
		}
	}

	&__dropdown {
		position: absolute;
		top: calc(100% + 2px);
		left: 0;
		width: 100%;
		height: auto;
		max-height: 312px;
		padding: 6px 0;
		background-color: v-bind(fillColorHover);
		border: 1px solid v-bind(borderColorHover);
		border-radius: 4px;
		overflow: auto;
		z-index: 1;
		box-shadow: $box-shadow;
	}

	&__dropdown-no-results {
		padding: 10px 12px;
		font-size: 14px;
		line-height: 1.14;
		color: v-bind(textAndIconColorHover);
	}

	&__loader {
		margin-left: auto;
		margin-right: auto;
	}
}

.search-result-item {
	display: flex;
	padding: 6px 12px;
	width: 100%;
	gap: $gap-between-image-and-content;
	text-decoration: none;
	transition: background-color #{$input-transition-timing-function} #{$input-transition-duration};
	cursor: pointer;

	&--highlighted,
	&:hover {
		background-color: v-bind(resultItemHoverColor);
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 4px;
		width: calc(100% - $image-width - $gap-between-image-and-content);
	}

	&__image {
		width: v-bind(IMAGE_DIMENSIONS_PX_STRING);
		height: v-bind(IMAGE_DIMENSIONS_PX_STRING);
	}

	&__title {
		font-size: 14px;
		font-weight: bold;
		line-height: 1.14;
		color: v-bind(textAndIconColorHover);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}

	&__price {
		display: flex;
		font-size: 14px;
		line-height: 1.14;
		font-weight: 400;
		color: v-bind(textAndIconColorHover);
	}

	&__sale-price {
		margin-right: 4px;
		text-decoration: line-through;
		opacity: 0.6;
	}
}
</style>
