import { SEARCH_INDICE_PRODUCTS } from '@zyro-inc/site-modules/constants';

const PUBLIC_API = `${import.meta.env.VITE_BACKEND_API_URL}/public`;

export const search = async ({
	query,
	indices,
	pageSize = 5,
	storeId,
}: {query: string, indices: [typeof SEARCH_INDICE_PRODUCTS], pageSize?: number, storeId: string }) => {
	if (!query) {
		return [];
	}

	const response = await fetch(`${PUBLIC_API}/search`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			query,
			indices,
			storeId,
			pageSize,
		}),
	});
	const { results } = await response.json();

	return results.products.hits;
};
